import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Section, Container, SectionImageBkg } from "../components/Section";
import {
  Nodata,
  NodataTitle,
  NodataDesc,
  NodataMedia,
} from "../components/Nodata";
import CategoryCarousel from "../components/CategoryCarousel";

const ThankYouPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Thank You - Panhandle Metals"
        description="Thanks for preferring Panhandle Metals for your metal building requirements."
        location={location}
      />

      <Section
        textAlign="center"
        ept="240px"
        epb="120px"
        xpt="120px"
        xpb="80px"
        pt="120px"
        pb="40px"
        bgColor="#F6F8FC"
        className="section-thank"
      >
        <Container maxWidth="980px">
          <Nodata>
            <NodataMedia>
              <StaticImage src="../images/thank-you.png" alt="thank-you" />
            </NodataMedia>
            <NodataTitle className="h2 mb-15 fw-800">THANK YOU!</NodataTitle>
            <NodataDesc mb="30px">
              <p>
                Thank you for choosing Panhandle Metal! Our team of experts is
                ready to provide you with a detailed, professional quote for
                your upcoming building project. We are committed to ensuring
                that you get the highest quality metal building in the industry.
              </p>
            </NodataDesc>
          </Nodata>
        </Container>
      </Section>

      <Section
        ept="120px"
        epb="120px"
        xpt="80px"
        xpb="80px"
        pt="40px"
        pb="40px"
        bgColor="#fff"
        className="section-buy"
      >
        <SectionImageBkg height="50%" bgColor="#161420" opacityImg="0.3">
          <StaticImage src="../images/black-vector.jpg" alt="black-vector" />
        </SectionImageBkg>
        <CategoryCarousel
          data={data.allContentfulCategory.edges}
          current="all"
        />
      </Section>
    </Layout>
  );
};

export default ThankYouPage;

export const pageQuery = graphql`
  query ThankyouPageQuery {
    allContentfulCategory {
      edges {
        node {
          name
          url
          mainImage {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
  }
`;
