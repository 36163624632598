import React, { useRef } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons";
import { SectionTitle, Container } from "../../components/Section";

const SlickCarousel = styled.div`
  .container {
    padding: 0;
    @media (min-width: 576px) {
      padding: 0 30px;
    }
  }
`;
const SlickSlideCarousel = styled.div`
  padding-bottom: 60px;
  .slick-dots {
    bottom: 100%;
    right: 0;
    z-index: 1;
    width: auto;
    li {
      height: 1px;
      margin: 0;
      background-color: #fff;
      width: 20px;
      @media (min-width: 992px) {
        width: 40px;
      }
      button {
        height: 5px;
        margin: -2px 0 0;
        padding: 0;
        background-color: transparent;
        width: 20px;
        @media (min-width: 992px) {
          width: 40px;
        }
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background-color: #c20000;
        }
      }
    }
  }
`;
const SlickItem = styled.div`
  outline: none;
  .card {
    margin: 15px 5px;
    @media (min-width: 576px) {
      margin: 15px 10px;
    }
    @media (min-width: 1440px) {
      margin: 20px;
    }
  }
`;

const CardCategory = styled.div`
  position: relative;
  background-color: #2d2c30;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 260px;
  padding: 30px 20px 20px;
  transition: transform 0.45s ease, background 0.45s ease;
  &::before {
    content: "";
    background: rgba(255, 72, 72, 0.8);
    border-radius: 12px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    clip-path: circle(0% at 0% 0%);
    transition: all 0.3s ease-in;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    margin: 0;
    border-radius: 12px;
    opacity: 0.2;
    transition: all 0.3s ease-in;
  }
  &:hover {
    transform: scale(1.02);
    .card-icon {
      svg {
        animation: toRightFromLeft 0.6s forwards;
      }
    }
    .card-title {
      opacity: 1;
      transform: none;
      z-inde: 1;
    }
    &:before {
      clip-path: circle(100%);
    }
    .gatsby-image-wrapper {
      opacity: 1;
    }
  }

  @media (min-width: 1440px) {
    height: 300px;
  }
  @media (min-width: 1600px) {
    height: 360px;
  }
`;
const CardTitle = styled.div`
  font-weight: 500;
  color: #fff;
  transform: translateY(-1em);
  transition: opacity 0.45s ease, transform 0.5s ease;
  position: relative;
  z-index: 1;
`;
const CategoryIcon = styled.div`
  position: relative;
  z-index: 1;
  svg {
    fill: #fff;
  }
`;
const CategoryType = styled.div`
  .container {
    @media (min-width: 1410px) {
      max-width: inherit;
      padding-left: calc((100% - 1410px) / 2);
      padding-right: 0;
    }
  }
`;

const SlickArrowTrack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 105px;
  right: 15px;
  @media (min-width: 576px) {
    right: 40px;
  }
  @media (min-width: 1410px) {
    right: 20px;
  }
`;

const LeftArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: 2px solid #161420;
  background-color: transparent;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  svg {
    transform: scale(0.8);
    opacity: 1;
    transition: transform 0.2s, opacity 0.2s;

    fill: #161420;
    width: 12px;
    @media (min-width: 992px) {
      width: 14px;
    }
    @media (min-width: 1200px) {
      width: 16px;
    }
    @media (min-width: 1600px) {
      width: 20px;
    }
  }
  &:hover {
    background-color: #c20000;
    svg {
      fill: #fff;
      transform: scale(1);
      opacity: 1;
    }
  }
`;
const RightArrow = styled(LeftArrow)``;

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      aria-label="button"
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowLeftIcon />
    </button>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      aria-label="button"
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowRightIcon />
    </button>
  );
}
const CategoryTabCarousel = ({ data, current }) => {
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    centerPadding: "0",
    infinite: true,
    focusOnSelect: true,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CircleNextArrow />,
    prevArrow: <CirclePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px",
        },
      },
    ],
  };

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <CategoryType>
      <Container className="container">
        <SectionTitle color="#fff" className="h3 mb-30">
          All Types of <span className="stroke-white">Structures</span>
        </SectionTitle>
      </Container>

      <SlickCarousel>
        <Container className="container">
          <SlickSlideCarousel>
            <Slider {...settings} ref={sliderRef}>
              {data.map((item, i) => {
                if (current !== item.node.name) {
                  return (
                    <SlickItem key={i}>
                      <Link to={item.node.url}>
                        <CardCategory className="card">
                          <CardTitle className="card-title">
                            TX {item.node.name}
                          </CardTitle>
                          <GatsbyImage
                            image={item.node.mainImage.gatsbyImageData}
                            alt={item.node.name}
                          />
                          <CategoryIcon className="card-icon">
                            <ArrowLeftIcon />
                          </CategoryIcon>
                        </CardCategory>
                      </Link>
                    </SlickItem>
                  );
                } else {
                  return true;
                }
              })}
            </Slider>
          </SlickSlideCarousel>
          <SlickArrowTrack>
            <LeftArrow aria-label="previous" onClick={() => previous()}>
              <ArrowRightIcon />
            </LeftArrow>
            <RightArrow aria-label="next" onClick={() => next()}>
              <ArrowLeftIcon />
            </RightArrow>
          </SlickArrowTrack>
        </Container>
      </SlickCarousel>
    </CategoryType>
  );
};

export default CategoryTabCarousel;
